import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.addClass = this.data.get('addClass') || 'nested-field-add'
    this.elementClass = this.data.get('elementClass') || 'nested-field'
    this.groupClass = this.data.get('groupClass') || 'nested-field-group'
    this.removeParent = this.data.get('removeParent') || false
  }

  addAssociation(event) {
    event.preventDefault()

    let setElement = event.target.closest('.' + this.groupClass)
    let templateElement = setElement.querySelector('template')
    let placeholderId = templateElement.getAttribute('data-placeholder')
    let placeholderPattern = new RegExp(placeholderId, 'g')
    let linksElement = setElement.querySelector(':scope > .' + this.addClass)

    var content = templateElement.innerHTML.replace(placeholderPattern, new Date().getTime())
    linksElement.insertAdjacentHTML('beforebegin', content)
  }

  removeAssociation(event) {
    event.preventDefault()

    let element = event.target.closest('.' + this.elementClass)

    // New records get removed
    if (element.dataset.newRecord === 'true') {
      element.remove()
    // Existing records are hidden and flagged for deletion
    } else {
      element.querySelector(':not(.nested-field) input[name*="active"]').value = false
      element.classList.add('d-none')
    }

    // Optionally, remove parent if it's the last one
    if (this.removeParent && element.classList.contains(this.elementClass)) {
      let set = element.closest('.' + this.groupClass)

      if (set.querySelectorAll('.' + this.elementClass + ':not(.d-none)').length === 0) {
        set.closest('.' + this.elementClass).classList.add('d-none')
        set.querySelectorAll('input[name*="_destroy"]').forEach((input) => {
          input.value = true
        })
      }
    }
  }
}
