// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// 2 lines below to import babel - IE11 crap
import "core-js/stable";
import "regenerator-runtime/runtime";
import Rails from '@rails/ujs'
Rails.start()

// require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')
require('controllers')

import 'bootstrap'
import $ from 'jquery'
window.jQuery = $
window.$ = $

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Trix text editor
require("trix")
require("@rails/actiontext")

// Polyfill SVG external fragment support. Can be removed when we stop supporting IE11
import svg4everybody from 'svg4everybody'
svg4everybody()

// Charting support
import ApexCharts from 'apexcharts'
window.ApexCharts = ApexCharts
